import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { useSelector } from 'react-redux';

const ThankYouPage = (props) => {
    const formState = useSelector((state) => state.formState);
    const meta = useSelector((state) => state.meta);
    const base_url = 'https://cpcman.torchte.ch/fetch/renderPage?site=5';

    return (
        <Layout location={props.location}>
            <Seo
                title="Thank You | Quick Health Insure"
                description="Low cost health insurance optionsfor you and your family. Now in all 50 states"
                image="http://medicaremaster.org/src/images/slide03.jpg"
            />
            <div className="thank-you-page-container">
                <div className="thank-you-page">
                    <h2>
                    Thank You!
                    </h2>
                    <p>One of our agents will be in touch with you soon to discuss your Medicare options.<br/>Below are your instant online quotes.</p>
                    <div className="iframe">
                        <iframe
                            title="cpcman"
                            src={`${base_url}&affid=${meta.client_name}&sub_id1=${meta.sub_id1}&zip=${formState.home_zip}&ip_address=${meta.ip_address}&placement_id=&placement_id2=KEhaiYVhNFXFaLxlVgblNUFsxJmNlg&publisherId=40823&campaign=23908&displayId=16819&vmProdId=501&dob=${formState.dob}&gender=${formState.gender}&param1=227882&param2=268397&param3=3530`}
                            frameBorder="0"
                        />
                    </div>

                </div>
            </div>
        </Layout>
    );
};

export default ThankYouPage;
